import { inject, Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { take, tap } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectPastBillingCycles } from '@app/resources/ngrx/selectors';
import { BillingCycleActions } from '@app/resources/ngrx/actions';

@Injectable()
export class BillingCycleModuleRouteResolver implements Resolve<any> {
  private readonly store = inject(Store);

  resolve(route: ActivatedRouteSnapshot) {
    return this.store.select(selectPastBillingCycles).pipe(
      take(1),
      tap((billingCycles) => {
        this.store.dispatch(BillingCycleActions.loadPastBillingCycles());
      })
    );
  }
}
