import { Component, inject } from '@angular/core';
import { EpTableComponent, EPTableConfig } from '@ep/shared';
import { Store } from '@ngrx/store';
import { BillingCycle } from '@app/resources/services';
import { BillingCycleModuleActions } from '@app/areas/billing-cycles/store';
import { combineLatest, Observable, tap } from 'rxjs';
import {
  selectBillingCycleTableFirst,
  selectBillingCycleTableRows,
  selectPastBillingCycles,
} from '@app/resources/ngrx/selectors';
import { BillingCycleActions, RouterActions } from '@app/resources/ngrx/actions';
import { BillingCyclesApiService } from '@app/areas/billing-cycles/services';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-billing-cycles-table',
  standalone: true,
  imports: [EpTableComponent],
  templateUrl: './billing-cycles-table.component.html',
  styleUrl: './billing-cycles-table.component.scss',
})
export class BillingCyclesTableComponent {
  private readonly billingCycleApiService = inject(BillingCyclesApiService);
  private readonly store = inject(Store);

  protected config: EPTableConfig<BillingCycle> = {
    header: 'billing cycles',
    pagination: {
      rows: 10,
      showCurrentPageReport: true,
    },
    columns: [
      {
        header: 'Name',
        field: 'Name',
        columnType: 'string',
        defaultValue: 'Missing billing cycle date',
        disableSorting: true,
      },
    ],
    style: {},
    globalFilterFields: ['Name'],
    actions: {
      rowClick: (billingCycle: BillingCycle) => {
        if (billingCycle.BillingCycleId == 0) {
          this.store.dispatch(
            RouterActions.navigateByUrlWithCoalition({ payload: { path: 'billing-cycles/current' } })
          );
        } else {
          this.store.dispatch(
            BillingCycleActions.pastBillingCycleById({
              payload: { billingCycleId: billingCycle.BillingCycleId },
            })
          );
          this.store.dispatch(
            BillingCycleModuleActions.navigatePastBillingCycle({
              payload: {
                billingCycleId: billingCycle.BillingCycleId,
              },
            })
          );
        }
      },
    },
    rowsPerPageOptions: [5, 10],
    first$: this.store.select(selectBillingCycleTableFirst),
    rows$: this.store.select(selectBillingCycleTableRows),
  };

  protected billingCycles$: Observable<BillingCycle[]> = combineLatest([
    this.billingCycleApiService.getCurrentMonthBillingCycle(),
    // this.billingCycleApiService.getPastBillingCycles(),
    this.store.select(selectPastBillingCycles),
  ]).pipe(
    map(([billingCycle, billingCycles]) => {
      return [billingCycle, ...billingCycles];
    })
  );

  protected onPageChange(event: any) {
    this.store.dispatch(
      BillingCycleActions.updateBillingCycleTablePage({
        payload: event,
      })
    );
  }
}
