<div class="billing-tree-table">
  <p-treeTable
    [value]="merchantInvoiceTreeNodes()"
    [paginator]="true"
    [rows]="10"
    [scrollable]="true"
    filterMode="strict"
    [globalFilterFields]="['name']"
    [tableStyle]="{ 'min-width': '50rem' }"
  >
    <ng-template pTemplate="caption">
      <div class="flex justify-content-end align-items-center">
        <div class="p-input-icon-left">
          <i class="pi pi-search"></i>
          <input
            pInputText
            type="text"
            [formControl]="globalSearchControl"
            placeholder="Search Merchant Name"
            data-cy="billing-tree-table-search-input"
          />
        </div>
      </div>
    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th ttSortableColumn="name">
          Name
          <p-treeTableSortIcon field="name" />
        </th>
        <th>Quantity</th>
        <th ttSortableColumn="billedAmount">
          Billed Amount
          <p-treeTableSortIcon field="billedAmount" />
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowNode let-rowData="rowData" let-index="index">
      <tr [ttRow]="rowNode" [ngClass]="{ 'transparent-gray': index % 2 == 0 }">
        <td>
          <p-treeTableToggler [rowNode]="rowNode" />
          {{ rowData.name }}
        </td>
        <td>{{ rowData.quantity }}</td>
        <td>{{ rowData.billedAmount | currency }}</td>
      </tr>
    </ng-template>
  </p-treeTable>
</div>
